import axios from 'axios';
import authHeader from "@/service/AuthHeader";
import getEnvironment from "@/service/Environment";

const API_LOGGED_IN_URL = getEnvironment().API_LOGGED_IN_URL + 'message';
const API_URL = getEnvironment().API_URL;

export default class MessageService {
    getAll(type) {
        return axios.get(API_LOGGED_IN_URL + `/list/${type}`, { headers: authHeader() });
    }

    get(id) {
        return axios.get(API_LOGGED_IN_URL + `/view/${id}`, { headers: authHeader() });
    }

    create(data) {
        const header = Object.assign({'Content-Type': 'application/json'}, authHeader());
        return axios.post(API_LOGGED_IN_URL + '/save', data, { headers:  header });
    }

    update(id, data) {
        return axios.put(API_URL + `/${id}`, data, { headers: authHeader() });
    }

    delete(id) {
        return axios.delete(API_URL + `/${id}`, { headers: authHeader() });
    }
}
